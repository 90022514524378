@import '~@angular/material/prebuilt-themes/indigo-pink.css';

:root {
  --primary-color: #18b69b;
  --secondary-color: #24325f;
}

html {
  box-sizing: border-box;
  overflow-y: scroll;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  src: url('./assets/font/rubik.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src: url('./assets/font/rubik.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  src: url('./assets/font/rubik.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 600;
  src: url('./assets/font/rubik.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  src: url('./assets/font/rubik.ttf') format('truetype');
}

body {
  margin: 0;
  //Font
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 15px;
}

img {
  max-width: 100%;
}
